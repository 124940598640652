import React, { useState, useEffect } from 'react'
import "./assets/styles/_index.scss"
import { Link, navigate } from 'gatsby';
import { PageLinks } from '../../common/site/page-static-links';
const FloatingButton = (props) => {
    // Sticky scroll
    const [scroll, setScroll] = useState(false);

    useEffect(() => {
        window.addEventListener("scroll", () => {
            setScroll(window.scrollY > 450)
        })
        if (scroll) {
            document.body.classList.add('chat-with-button-scroll');
        } else {
            document.body.classList.remove('chat-with-button-scroll');
        }
    }, [scroll])
    // Sticky scroll

    return (
        <div className='d-xl-none'>
            {scroll &&
                <section className={`floating-button-wrapper ${props.tag}`}>
                    {props.tag === "property-details" &&
                        <>
                            {!props.isSoldProperty &&
                                <a
                                    href="javascript:;"
                                    onClick={() => navigate(`/${PageLinks.book_a_viewing}/`,
                                        {
                                            state: {
                                                type: props.type,
                                                id: props.crm_id,
                                                address: props?.address,
                                                pageurl: props.page_url,
                                                propImage: props.propImage,
                                                to_email_id: props?.negDetails[0]?.node?.email
                                            }
                                        }
                                    )}
                                    className='button button-primary button-with-icon'
                                >
                                    <i className='icon icon-envelope-white'></i>Email
                                </a>
                            }
                            {!props.isSoldProperty &&<a href={`tel: ${props?.negDetails[0]?.node?.phone}`} className="button button-primary-light button-with-icon"><i className='icon icon-phone-sticky'></i>Call</a>}
                            <Link to={`/${PageLinks.valuation}/`} className="button button-primary-light button-with-icon d-md-flex d-none"><i className='icon  icon-arrow-up'></i>book a valuation</Link>
                        </>
                    }

                    {props.tag === "office-detail" &&
                        <>
                            <a href="javascript:;" onClick={() => navigate(`/${PageLinks.enquiry}/`, { state: { officename: props.title, to_email_id: props.email } })} className='button button-primary button-with-icon'><i className='icon icon-envelope-white'></i>Enquire</a>
                            <a href={`tel: ${props.phone}`} className="button button-primary-light button-with-icon"><i className='icon icon-phone-sticky'></i>Call</a>
                            <Link to={`/${PageLinks.valuation}/`} className="button button-primary-light button-with-icon d-md-flex d-none"><i className='icon  icon-arrow-up'></i>book a valuation</Link>
                        </>
                    }
                    {props.tag === "career-detail" &&
                        <>
                            <Link to={`/${PageLinks.send_cv}/`} className='button button-primary button-with-icon'><i className='icon icon-envelope-white'></i>Apply for this job</Link>
                            <Link to={`/${PageLinks.send_cv}/`} className="button button-primary-light button-with-icon d-md-flex d-none"><i className='icon  icon-arrow-up'></i>send us your cv</Link>
                        </>
                    }
                </section>
            }
        </div>
    )
}

export default FloatingButton