import { Link } from 'gatsby'
import React from 'react'
import NegoCard from '../NegoCard/NegoCard'
import { PageLinks } from '../../common/site/page-static-links'

const CareerDetailSidebar = (props) => {
    return (
        <div className='career-detail-sidebar-wrapper'>
            {props.showApplyButton &&
                <div className='btn-wrapper'>
                    <Link className='button button-primary button-with-icon' to={`/${PageLinks.send_cv}/`}><i className='icon icon-envelope-white'></i>Apply for this Job</Link>
                </div>
            }
            <NegoCard author={props.author} />
            <div className='divider-line'></div>
            <div className='sidebar-pattern'>
                <h3>Not quite right for you?</h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin lacus nunc, aliquet vel dapibu.</p>
                <Link className='button button-primary-light button-with-icon' to={`/${PageLinks.send_cv}/`}><i className='icon icon-arrow-up'></i>send us your cv</Link>
            </div>
        </div>
    )
}

export default CareerDetailSidebar