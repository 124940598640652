import React from 'react'
import "./assets/styles/_index.scss"
import { Link } from 'gatsby'
import { PageLinks } from '../../common/site/page-static-links'

import { useAllStrapiTeam } from "../../hooks/use-all-strapiteam";
import GGFXImage from '../GGFX/GGFXImage';

const { ImageModule } = require("@starberry/gatsby-theme-utils/Modules/ImageModule")
const NegoCard = ({ author }) => {
    let imagename = "team.image.nego_image"

    var team_data = useAllStrapiTeam()
    team_data = team_data.allStrapiTeam.nodes
    let team_key;
    if (author?.length > 0) {
        for (let k in team_data) {
            if (author[0].strapi_id == team_data[k].strapi_id) {
                team_key = k;
                break;
            }
        }
    }

    return (
        <>
            {team_data[team_key] &&
                <div className='nego-wrapper'>
                    <div className='img-wrapper-section img-wrapper'>
                        {/* {author?.image?.url} */}
                        <GGFXImage
                            ImageSrc={team_data[team_key]?.image}
                            altText={team_data[team_key]?.image?.alternativeText || author.name}
                            imagetransforms={team_data[team_key]?.ggfx_results}
                            renderer="srcSet"
                            imagename={imagename}
                            strapiID={team_data[team_key]?.strapi_id}
                            classNames="img-fluid"
                        />
                    </div>
                    <div className='text-wrapper'>
                        <div className='nego-name'>{team_data[team_key].name}</div>
                        <p className='nego-role'>{team_data[team_key].designation}</p>
                        <div className='nego-list'>
                            {team_data[team_key].phone && <a href={`tel:${author.phone}`} className='phone'><i className='icon icon-phone'></i>{team_data[team_key].phone}</a>}
                            {team_data[team_key].phone && <span>|</span>}
                            <i className='icon icon-envelope'></i><Link to={`/${PageLinks.enquiry}/`} className="link-underline nego-email">Email</Link>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default NegoCard