import React from 'react'
import { graphql } from 'gatsby';
import Seo from '../components/seo';
import LayoutTwo from '../components/layoutTwo'
import { PageLinks } from '../common/site/page-static-links';
import BreadcrumbModule from '../components/BreadcrumbModule/BreadcrumbModule'
import CareerDetailLanding from '../components/CareerDetailLanding/CareerDetailLanding'
import FloatingButton from '../components/FloatingButton/FloatingButton';

const CareerDetails = ({ data }) => {
    const PageData = data?.strapiCareer
    let breadcrumData;

    if (PageLinks?.career_parent_label) {
        breadcrumData = { parentlabel: PageLinks.career_parent_label, parentlink: PageLinks.career_parent, subparentlabel: PageLinks.career_label, subparentlink: PageLinks.career, title: PageData.title }
    } else {
        breadcrumData = { parentlabel: PageLinks.career_label, parentlink: PageLinks.career, title: PageData.title }
    }

    return (
        <LayoutTwo footerClass="footer-cta">
            <div className="layout-padding-top"></div>
            <BreadcrumbModule {...breadcrumData} />
            <div className='layout-grey-gradient-bg'>
                <CareerDetailLanding {...PageData}/>
            </div>
            <div className='divider-line'></div>
            <FloatingButton tag="career-detail" />
        </LayoutTwo>
    )
}

export const Head = ({ data }) => {
    const PageData = data?.strapiCareer
    const metaDescription = `Join our dynamic team as a ${PageData.title} at ${process.env.GATSBY_SITE_NAME}. Take the first step towards a rewarding real estate career with our established agency. Apply now!`

    return (
        <Seo title={PageData.title} description={metaDescription} />
    )
}

export default CareerDetails

export const query = graphql`
query ($page_id: Int) {
    strapiCareer(strapi_id: {eq: $page_id}) {
        ...CareerFragment
        job_details {
            data {
                job_details
            }
        }
        image {
            alternativeText
            url
        }
        imagetransforms {
            image_Transforms
        }
        contacts {
            name
            designation
            email
            phone
            strapi_id
        }
    }
}`