import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'


import './assets/styles/_index.scss'
import CareerDetailSidebar from './CareerDetailSidebar';

const { ContentModule } = require("@starberry/gatsby-theme-utils/Modules/ContentModule");

const CareerDetailLanding = (props) => {

    // Sticky scroll
    const [scroll, setScroll] = useState(false);

    useEffect(() => {
        window.addEventListener("scroll", () => {
            setScroll(window.scrollY > 250)
        })
    }, [])
    // Sticky scroll
    return (
        <div className='career-detail-wrapper'>
            <Container>
                <Row>
                    <Col xl={7}>
                        <div className='career-detail-intro'>
                            <h1>{props.title}</h1>
                            <div className='career-detail-list d-flex align-items-center'>
                                {props.location && <p className='button-with-icon'><i className='icon icon-location'></i>{props.location}</p> }
                                {props.category && <span>|</span> }
                                {props.category && <p className='button-with-icon'><i className='icon icon-briefcase'></i>{props.category}</p> }
                            </div>
                        </div>
                        {props.contact?.image?.url}
                        <div className='career-detail-desc'>
                            <ContentModule Content={props.job_details?.data.job_details} />
                        </div>
                        <div className='d-xl-none d-block'>
                            <CareerDetailSidebar author={props.contacts ? props.contacts : ''}/>
                        </div>
                    </Col>
                    <Col xl={1}></Col>
                    <Col xl={4} className="d-xl-block d-none sidebar-column">
                        <div className={`career-sidebar  ${scroll ? "scrolled" : ""}`}>
                            <CareerDetailSidebar author={props.contacts ? props.contacts : ''} showApplyButton/>
                        </div>
                    </Col>
                </Row>

            </Container>
        </div>
    )
}

export default CareerDetailLanding